/* globals jsLibLoader:false */
(function ($, jsLibLoader) {
  Drupal.behaviors.tltrendsv1 = {
    attach: function (context, settings) {
      var $tlTrends = $('.tl-trends-v1', context);

      if ($tlTrends.length > 0 && !!settings.tl_trends_cdn_path) {
        // If TL Trends is not enabled, do nothing
        var $searchForm = $('#perlgem-search-form', context);

        $tlTrends.appendTo($searchForm.addClass('tl-trends-v1__form'));
        this.init(settings, context, $tlTrends);
      }
    },
    init: function (settings, context, $elem) {
      jsLibLoader
        .load(settings.tl_trends_cdn_path)
        .then(function () {
          var $inputSearch = $('.header-search__field.js-search-field', context);
          var $typeaheadWrapper = $(
            '.header-search .header-search__form .js-typeahead-wrapper',
            context
          );

          $elem.removeClass('hidden');
          $inputSearch.on('focus change keypress input', function (ev) {
            $elem.toggleClass('hidden', ev.currentTarget.value !== '');
            $typeaheadWrapper.toggleClass('text-empty', ev.currentTarget.value === '');
          });
        })
        .catch($elem.remove);
    }
  };
})(jQuery, new jsLibLoader());
